class Artillery {
    /**
     * Represents an artillery shell with specific mass, air drag coefficient, and initial velocity.
     * Also contains a boolean to tell if this artillery is shot at a low angle or high angle.
     *
     * @param {string} name - The name of the artillery.
     * @param {number} mass - Mass of the artillery shell in kilograms.
     * @param {number} airDrag - Air drag coefficient.
     * @param {number} initialVelocity - Initial velocity of the shell in meters per second.
     * @param {boolean} highAngle - Whether the artillery shoots at an angle greater than 45 degrees.
     */
    name: string;
    mass: number;
    airDrag: number;
    initialVelocity: number;
    highAngle: boolean;

    constructor(
        name: string,
        mass: number,
        airDrag: number,
        initialVelocity: number,
        highAngle: boolean
    ) {
        this.name = name;
        this.mass = mass;
        this.airDrag = airDrag;
        this.initialVelocity = initialVelocity;
        this.highAngle = highAngle;
    }
}

class GridCoord {
    /**
     * Represents a coordinate point in a grid system with easting and northing values.
     * @param {number} easting - The easting coordinate value.
     * @param {number} northing - The northing coordinate value.
     */
    easting: number;
    northing: number;

    constructor(easting: number, northing: number) {
        this.easting = easting;
        this.northing = northing;
    }

    toString() {
        return `(${this.easting}, ${this.northing})`;
    }
}

function deg2EL(degrees: number) {
    return ((degrees * Math.PI) / 180) * 1000 - 960;
}

function distancefunc(
    x1: number,
    y1: number,
    z1: number,
    x2: number,
    y2: number,
    z2: number
) {
    return Math.sqrt(
        Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2) + Math.pow(z2 - z1, 2)
    );
}

function rotatePointClockwise(point: Array<number>, degRotation: number) {
    const radRotation = (degRotation * Math.PI) / 180;
    const cos = Math.cos(radRotation);
    const sin = Math.sin(radRotation);
    const rotationMatrix = [
        [cos, sin],
        [-sin, cos],
    ];
    return [
        point[0] * rotationMatrix[0][0] + point[1] * rotationMatrix[0][1],
        point[0] * rotationMatrix[1][0] + point[1] * rotationMatrix[1][1],
    ];
}

function rotatePolygonClockwise(
    points: Array<Array<number>>,
    degRotation: number
) {
    const totalX = points.reduce((sum, point) => sum + point[0], 0);
    const totalY = points.reduce((sum, point) => sum + point[1], 0);
    const center = [totalX / points.length, totalY / points.length];

    const centeredPoints = points.map((point) => [
        point[0] - center[0],
        point[1] - center[1],
    ]);
    const rotatedPoints = centeredPoints.map((point) =>
        rotatePointClockwise(point, degRotation)
    );

    return rotatedPoints.map((point) => [
        point[0] + center[0],
        point[1] + center[1],
    ]);
}

function generateLinearSheafPoints(
    startPoint: Array<number>,
    endPoint: Array<number>,
    mortarRadius: number
) {
    const distance = distancefunc(
        startPoint[0],
        startPoint[1],
        0,
        endPoint[0],
        endPoint[1],
        0
    );
    const angle = Math.atan2(
        endPoint[1] - startPoint[1],
        endPoint[0] - startPoint[0]
    );
    const numPoints = Math.ceil(distance / mortarRadius);
    if (numPoints == 1) {
        return [
            new GridCoord(
                (startPoint[0] + endPoint[0]) / 2,
                (startPoint[1] + endPoint[1]) / 2
            ),
        ];
    }
    return [new GridCoord(0, 0)];

    // const angle = Math.atan2(endPoint[1] - startPoint[1], endPoint[0] - startPoint[0]);
    // const numPoints = Math.ceil(distance / mortarRadius);
    // const points = Array.from({ length: numPoints }, (_, i) => [
    //     startPoint[0] + i * mortarRadius * Math.cos(angle),
    //     startPoint[1] + i * mortarRadius * Math.sin(angle),
    // ]);
    // return points;
}

function ballisticSim(
    artillery: Artillery,
    elevation: number,
    targetHeight: number
) {
    const deltaT = 0.001;
    const includeDrag = true;
    const f = artillery.airDrag;
    const m = artillery.mass;
    const initialVelocity = artillery.initialVelocity;
    let t = 0;
    let position = [0, 0];
    const gravity = [0, -9.81];
    let velocity = [
        initialVelocity * Math.cos((elevation * Math.PI) / 180),
        initialVelocity * Math.sin((elevation * Math.PI) / 180),
    ];
    let lastValidPosition = [...position];
    let maxHeightPos = [...position];

    while (velocity[1] > 0 || position[1] > targetHeight) {
        const speed = Math.sqrt(velocity[0] ** 2 + velocity[1] ** 2);
        const dragSpeed = (f * speed ** 2) / m;
        const dragDirection = [-velocity[0] / speed, -velocity[1] / speed];
        const dragVector = [
            dragSpeed * dragDirection[0],
            dragSpeed * dragDirection[1],
        ];
        const deceleration = includeDrag
            ? [dragVector[0] + gravity[0], dragVector[1] + gravity[1]]
            : gravity;

        velocity[0] += deceleration[0] * deltaT;
        velocity[1] += deceleration[1] * deltaT;

        lastValidPosition = [...position];
        position[0] += velocity[0] * deltaT;
        position[1] += velocity[1] * deltaT;

        if (position[1] > maxHeightPos[1]) {
            maxHeightPos = [...position];
        }

        t += deltaT;
    }

    return [lastValidPosition[0], t - deltaT, maxHeightPos[1]];
}

function azimuthToVertAngle(azimuth: number) {
    return (90 - azimuth) % 360;
}

function azimuthFromObserverRelative(
    mortarPos: GridCoord,
    observerPos: GridCoord,
    obsToEnemyAzimuth: number,
    obsToEnemyHorizDistance: number
) {
    const enemyPos = new GridCoord(
        Number(observerPos.easting) +
            Math.cos((azimuthToVertAngle(obsToEnemyAzimuth) * Math.PI) / 180) *
                obsToEnemyHorizDistance,
        Number(observerPos.northing) +
            Math.sin((azimuthToVertAngle(obsToEnemyAzimuth) * Math.PI) / 180) *
                obsToEnemyHorizDistance
    );
    console.log("enemyPos", enemyPos);

    const distance = distancefunc(
        mortarPos.easting,
        mortarPos.northing,
        0,
        enemyPos.easting,
        enemyPos.northing,
        0
    );
    console.log("distance", distance);

    return [Math.round(azimuthFromGrids(mortarPos, enemyPos)), distance];
}

function azimuthFromGrids(startPos: GridCoord, endPos: GridCoord) {
    const startEndVector = new GridCoord(
        endPos.easting - startPos.easting,
        endPos.northing - startPos.northing
    );

    const temp = startEndVector.northing;
    startEndVector.northing = startEndVector.easting;
    startEndVector.easting = temp;

    let initial =
        (Math.atan2(startEndVector.northing, startEndVector.easting) * 180) /
        Math.PI;
    if (initial < 0) {
        initial = 360 + initial;
    }

    return initial;
}

function calculateElevation(
    artillery: Artillery,
    targetHeight: number,
    distance: number
) {
    const maxAttempts = 100;
    let currentElevation = 45;
    let currentDistance = ballisticSim(
        artillery,
        currentElevation,
        targetHeight
    )[0];
    let maxElevation = artillery.highAngle ? 90 : 45;
    let minElevation = artillery.highAngle ? 45 : 0;
    let attemptCount = 0;

    while (
        Math.abs(currentDistance - distance) > 0.5 &&
        attemptCount < maxAttempts
    ) {
        attemptCount++;
        currentElevation = (minElevation + maxElevation) / 2;
        currentDistance = ballisticSim(
            artillery,
            currentElevation,
            targetHeight
        )[0];

        if (artillery.highAngle) {
            if (currentDistance < distance) {
                maxElevation = currentElevation;
            } else {
                minElevation = currentElevation;
            }
        } else {
            if (currentDistance < distance) {
                minElevation = currentElevation;
            } else {
                maxElevation = currentElevation;
            }
        }
    }

    if (Math.abs(currentDistance - distance) > 0.5) {
        return { elevation: -1, timeToImpact: -1, maxOrd: -1 };
    }

    let elevation = ((currentElevation * Math.PI) / 180) * 1000;

    const ballisticSimResult = ballisticSim(
        artillery,
        currentElevation,
        targetHeight
    );
    return {
        elevation: elevation,
        timeToImpact: parseFloat(ballisticSimResult[1].toFixed(1)),
        maxOrd: Math.round(ballisticSimResult[2]),
    };
}

function generateDistanceElevationTable(
    artillery: Artillery,
    distances: Array<number>
) {
    try {
        distances.forEach((distance) => {
            const elevation = calculateElevation(
                artillery,
                0,
                distance
            ).elevation;
            console.log(
                `${distance.toString().padStart(10)} m | ${Math.round(
                    elevation / 1000
                )
                    .toString()
                    .padStart(5)} milirads`
            );
        });
    } catch (e) {
        console.error("Error generating distance elevation table:", e);
    }
}

function calculateAdjustedCoordinates(
    targetPos: GridCoord,
    correction: GridCoord,
    observerToEnemyAzimuth: number
) {
    let correctionDirection = 0;
    if (correction.easting > 0) {
        correctionDirection = (observerToEnemyAzimuth + 90) % 360;
    } else {
        correctionDirection = (observerToEnemyAzimuth - 90) % 360;
    }
    let correctionUnitVector = [
        Math.cos(radians(azimuthToVertAngle(correctionDirection))),
        Math.sin(radians(azimuthToVertAngle(correctionDirection))),
    ];
    let azimuthUnitVector = [
        Math.cos(radians(azimuthToVertAngle(observerToEnemyAzimuth))),
        Math.sin(radians(azimuthToVertAngle(observerToEnemyAzimuth))),
    ];
    let correctionVector = correctionUnitVector.map(
        (x) => x * correction.easting
    ); // left/right
    let azimuthVector = azimuthUnitVector.map((x) => x * correction.northing); // add/drop
    let totalAdjustmentVector = [
        correctionVector[0] + azimuthVector[0],
        correctionVector[1] + azimuthVector[1],
    ];
    let adjustedPos = new GridCoord(
        targetPos.easting + totalAdjustmentVector[0],
        targetPos.northing + totalAdjustmentVector[1]
    );
    return adjustedPos;
}

function radians(degrees: number) {
    return degrees * (Math.PI / 180);
}

function degrees(radians: number) {
    return radians * (180 / Math.PI);
}

const artilleryLib = {
    Artillery,
    GridCoord,
    deg2EL,
    distancefunc,
    rotatePointClockwise,
    rotatePolygonClockwise,
    ballisticSim,
    azimuthToVertAngle,
    azimuthFromObserverRelative,
    azimuthFromGrids,
    calculateElevation,
    generateDistanceElevationTable,
};

export default artilleryLib;
